const links = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "About Us",
        path: "/about",
        child: [{
            label: "Company Introduction",
            pathName: 'aboutUs-about',
            path: 'about',
            query: {
                flag: "about"
            }
        }, {
            label: "Our Technology",
            pathName: 'aboutUs-about',
            path: 'about',
            query: {
                flag: "Technology"
            }
        }, {
            label: "Qur Team",
            pathName: 'aboutUs-about',
            path: 'about',
            query: {
                flag: "Team"
            }
        }, {
            label: "Success Stories",
            pathName: 'aboutUs-about',
            path: 'about',
            query: {
                flag: "Success"
            }
        },]
    },
    {
        label: "Company products",
        // pathName: 'aboutUs-about',
        path: 'about',
        child: [{
            label: "Company products",
            pathName: 'aboutUs-about',
            path: 'about',
            query: {
                flag: "system"
            }
        }, {
            label: "White paper",
            // pathName: 'white-paper',
            outLink: 'https://sites.google.com/view/naiwstokenscom',
        },
        ]
    },
    {
        label: "Alpha Picks",
        pathName: 'AlphaPicks',
    },
    {
        label: "Market Interpretation",
        pathName: 'MarketInterpretation',
        query: {
            flag: "news"
        }
    },
    {
        label: "News",
        child: [{
            label: "American Equities",
            pathName: 'news',
            query: {
                flag: "AmericanEquities"
            }
        }, {
            label: "Capital Funds",
            pathName: 'news',
            query: {
                flag: "CapitalFunds"
            }
        }, {
            label: "Bond Securities",
            pathName: 'news',
            query: {
                flag: "BondSecurities"
            }
        }, {
            label: "Currency Markets",
            pathName: 'news',
            query: {
                flag: "CurrencyMarkets"
            }
        }, {
            label: "Virtual Currencies",
            pathName: 'news',
            query: {
                flag: "VirtualCurrencies"
            }
        }, {
            label: "Worldwide Economics",
            pathName: 'news',
            query: {
                flag: "WorldwideEconomics"
            }
        }, {
            label: "Portfolio Strategies",
            pathName: 'news',
            query: {
                flag: "PortfolioStrategies"
            }
        }, {
            label: "Compliance Policies",
            pathName: 'news',
            query: {
                flag: "CompliancePolicies"
            }
        }]
    }, {
        label: "Online Learning",
        child: [{
            label: "Learning Records",
            pathName: 'news',
            query: {
                flag: "Online-LearningRecords"
            }
        }, {
            label: "Portfolio Records",
            pathName: 'news',
            query: {
                flag: "Online-PortfolioRecords"
            }
        }]
    },
    {
        label: "Online Raffles",
        // outLink:'https://www.pea1.top/',
        // outLink:'http://38.180.251.175:8080/',
        outLink: 'https://jnaws.com/',
    },

]

export default links;
